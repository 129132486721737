import React, {useEffect, useState} from 'react';
import '../styles/Assistant.css';
import {MdDelete} from 'react-icons/md';
import {TbFileUpload} from "react-icons/tb";
import UploadFileModal from './UploadFileModal';
import {RiRobot3Line} from 'react-icons/ri';
import {SlPencil} from "react-icons/sl";
import {SlControlPlay} from "react-icons/sl";
import {SlArrowDownCircle} from "react-icons/sl";
import ConfirmModal from "./ConfirmModal";
import Spinner from "./Spinner";
import {
    getAssistants,
    createAssistant,
    updateAssistant,
    deleteAssistant,
    createVectorStore
} from '../clients/farmland'
import ErrorModal from "./ErrorModal";
import SelectFileModal from "./SelectFileModal";
import {DownloadHtml} from "../helper/htmlDownloader";
import AssistantFileListModal from "./AssistantFileListModal";
import Notification from "./Notification";
import {paginate} from "../helper/pagination";
import '../styles/Pagination.css'

const Assistant = () => {
    const [assistants, setAssistants] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [currentAssistant, setCurrentAssistant] = useState(null);
    const [isAdding, setIsAdding] = useState(false);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [isSelectModalOpen, setSelectModalOpen] = useState(false);
    const [error, setError] = useState('');
    const [isConfirmRequested, setIsConfirmRequested] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [isFilesModalOpen, setFilesModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [notification, setNotification] = useState(null);

    const pageSize = 5

    useEffect(() => {
        const fetchAssistants = async () => {
            setIsLoading(true);
            const { assistants, error } = await getAssistants();
            if (error) {
                setError(error);
            } else {
                setAssistants(assistants);
            }
            setIsLoading(false);
        };
        fetchAssistants().then();
    }, []);

    const { paginatedItems: paginatedAssistants, totalPages } = paginate(assistants, currentPage, pageSize);
    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage(currentPage - 1);
    };

    const editAssistant = (assistant) => {
        setIsEditing(true);
        setCurrentAssistant(assistant);
    };

    const handleFiles = (assistant) => {
        setFilesModalOpen(true)
        console.log(assistant)
        setCurrentAssistant(assistant)
    };

    const handleExport = (assistant) => {
        DownloadHtml({src: `https://www.assistant-factory.com/conversation/${assistant.id}`})
        setCurrentAssistant(assistant)
        setNotification({
            message: 'Assistant successfully exported, check your downloads',
            type: 'success'
        })
    };
    const handleDeleteFileButton = (assistant) => {
        setIsConfirmRequested(true)
        setCurrentAssistant(assistant)
    };

    const handlePlayground = (assistant) => {
        setCurrentAssistant(assistant)
        window.open(`https://www.assistant-factory.com/conversation/${currentAssistant.id}`, '_blank');
    };

    const handleSave = async () => {
        setIsLoading(true)
        if (isAdding) {
            const vectorStoreId = await createVectorStore(currentAssistant.name)
            await createAssistant(currentAssistant.name, currentAssistant.instructions, vectorStoreId)
            getAssistants().then(i => setAssistants(i.assistants))
            setIsAdding(false);
        }
        if (isEditing) {
            await updateAssistant(currentAssistant.id, currentAssistant.name, currentAssistant.instructions)
            setIsEditing(false);
        }

        setCurrentAssistant(null);
        await refreshAssistantList();
        setIsLoading(false)
        setNotification({
            message: isEditing ? 'Assistant successfully modified' : 'Assistant successfully created',
            type: 'success'
        })
    };
    const handleDelete = async () => {
        setIsLoading(true)
        await deleteAssistant(currentAssistant.id)
        setIsConfirmRequested(false);
        setCurrentAssistant(null)

        await refreshAssistantList();
        setIsLoading(false)
        setNotification({
            message: 'Assistant successfully deleted',
            type: 'success'
        })
    };

    const handleCancel = () => {
        setIsEditing(false);
        setIsAdding(false);
        setIsConfirmRequested(false);
        setCurrentAssistant(null);
    };

    const handleErrorOk = () => {
        setError('');
    };

    const handleChange = (e) => {
        const {name, value} = e.target;
        setCurrentAssistant((prevState) => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleAddNew = () => {
        setCurrentAssistant({name: '', instructions: ''});
        setIsAdding(true);
    };

    const closeModal = () => {
        setFilesModalOpen(false)
    }

    const handleCloseNotification = () => {
        setNotification(null);
    };

    const refreshAssistantList = async () => {
        const {assistants, error} = await getAssistants();
        setAssistants(assistants);
        if (error !== "") {
            setError(error);
        }
    }

    return (
        <div className="assistant-container">
            <div className="assistant-container-header">
                <h1>Assistants</h1>
                <button className="create-assistant-button" onClick={handleAddNew}>
                    + Create Assistant
                </button>
            </div>
            {
                isLoading ? (
                    <Spinner/>
                ) : assistants.length === 0 ? (
                    <p className="no-assistant">Henüz bir asistan oluşturulmadı.</p>
                ) : (
                    <>
                        <div className="assistant-list">
                            {paginatedAssistants.map((assistant) => (
                                <div key={assistant.id} className="assistant-card">
                                    <div className="assistant-card-header">
                                        <div className="assistant-icon">
                                            <RiRobot3Line size="40px"/>
                                        </div>
                                        <div className="assistant-buttons">
                                            <button
                                                className="playground-button"
                                                onClick={() => handlePlayground(assistant)}
                                                data-tooltip="Playground"
                                            >
                                                <SlControlPlay/>
                                            </button>
                                            <button
                                                className="edit-button"
                                                onClick={() => editAssistant(assistant)}
                                                data-tooltip="Edit"
                                            >
                                                <SlPencil/>
                                            </button>
                                            <button
                                                className="file-upload-button"
                                                onClick={() => handleFiles(assistant)}
                                                data-tooltip="Manage Files"
                                            >
                                                <TbFileUpload size="24px"/>
                                            </button>
                                            <button
                                                className="export-assistant-button"
                                                onClick={() => handleExport(assistant)}
                                                data-tooltip="Export"
                                            >
                                                <SlArrowDownCircle size="24px"/>
                                            </button>
                                            <button
                                                className="delete-assistant-button"
                                                onClick={() => handleDeleteFileButton(assistant)}
                                                data-tooltip="Delete"
                                            >
                                                <MdDelete/>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="assistant-details">
                                        <p>
                                            <strong>Name:</strong> {assistant.name} <br/>
                                            <strong>Model:</strong> {assistant.model} <br/>
                                            <strong>Instructions:</strong> {assistant.instructions}
                                        </p>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="pagination">
                            <button onClick={handlePreviousPage} disabled={currentPage === 1}>
                                Previous
                            </button>
                            <span>
                            Page {currentPage} of {totalPages}
                        </span>
                            <button onClick={handleNextPage} disabled={currentPage === totalPages}>
                                Next
                            </button>
                        </div>
                    </>
                )
            }
            {notification && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={handleCloseNotification}
                />
            )}

            {isEditing && (
                <div className="edit-assistant-modal">
                    <div className="edit-assistant-modal-content">
                        <h2>{'Modify assistant'}</h2>
                        <label>
                            <strong>Name</strong>
                            <input
                                type="text"
                                name="name"
                                value={currentAssistant?.name || ''}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            <strong>Instructions</strong>
                            <textarea
                                name="instructions"
                                value={currentAssistant?.instructions || ''}
                                onChange={handleChange}
                                rows="3"
                            />
                        </label>
                        <div className="modal-buttons">
                            <button className="cancel-button" onClick={handleCancel}>
                                Cancel
                            </button>
                            <button className="save-button" onClick={handleSave}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isAdding && (
                <div className="edit-assistant-modal">
                    <div className="edit-assistant-modal-content">
                        <h2>{'Add new assistant'}</h2>
                        <label>
                            <strong>Name</strong>
                            <input
                                type="text"
                                name="name"
                                value={currentAssistant?.name || ''}
                                onChange={handleChange}
                            />
                        </label>
                        <label>
                            <strong>Instructions</strong>
                            <textarea
                                name="instructions"
                                value={currentAssistant?.instructions || ''}
                                onChange={handleChange}
                                rows="3"
                            />
                        </label>
                        <div className="modal-buttons">
                            <button className="cancel-button" onClick={handleCancel}>
                                Cancel
                            </button>
                            <button className="save-button" onClick={handleSave}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {isFilesModalOpen && (
                <div className="assistant-files-modal">
                    <AssistantFileListModal vectorStoreId={currentAssistant.vectorStoreId}/>
                    <div className="assistant-files-modal-buttons">
                        <button className="close-button" onClick={closeModal}>
                            Close
                        </button>
                        <button className="upload-button" onClick={() => setIsUploadModalOpen(true)}>
                            Upload files
                        </button>
                    </div>
                </div>
            )}

            {isUploadModalOpen && (
                <div className="upload-file-container">
                    <div className="upload-file-modal">
                        <UploadFileModal
                            onClose={() => setIsUploadModalOpen(false)}
                            onUpload={() => setIsUploadModalOpen(false)}
                            setIsUploadModalOpen={setIsUploadModalOpen}
                            vectorStoreId={currentAssistant.vectorStoreId}
                        />
                        <button className="select-button" onClick={() => setSelectModalOpen(true)}>
                            Select from existing files
                        </button>
                        {isSelectModalOpen && (
                            <SelectFileModal
                                vectorStoreId={currentAssistant.vectorStoreId}
                                onClose={() => setSelectModalOpen(false)}
                            />
                        )}
                    </div>
                </div>
            )}


            {isConfirmRequested && (
                <div className="confirm-modal">
                    <ConfirmModal
                        handleCancel={handleCancel}
                        handleSave={handleDelete}
                        savePH={'Delete'}
                        confirmationText={"Do you want to delete assistant?"}
                    />
                </div>
            )}

            {
                error !== '' && (
                    <div className="assistant-error-modal">
                        <ErrorModal
                            handleErrorOk={handleErrorOk}
                            errorMessage={error}
                        />
                    </div>
                )
            }
        </div>
    );
};


export default Assistant;
