import { RiRobot3Line } from 'react-icons/ri';
import { FiSettings } from 'react-icons/fi';
import { IoIosLogOut } from 'react-icons/io';
import {useNavigate} from "react-router-dom";
import '../styles/Header.css'
import {deleteCookie} from "../helper/cookieHandler";

const Header = () => {
    const navigate = useNavigate()

    const handleSettingsClick = () => {
        navigate('/settings')
    }
    const handleLogoutClick = () => {
        deleteCookie('token')
        navigate('/home')
    }
    const handleLogoClick = () => {
        navigate('/dashboard');
    };

    return (
        <header className="header">
            <div className="logo-container" onClick={handleLogoClick}>
                <RiRobot3Line size="30px"/>
                <p>Assistant Factory</p>
            </div>
            <div className="buttons">
                <button className="settings-button" onClick={handleSettingsClick}>
                    <FiSettings size={25}/>
                </button>
                <button className="logout-button" onClick={handleLogoutClick}>
                    <IoIosLogOut size={25}/>
                </button>
            </div>
        </header>
    );
};

export default Header;
