import React from 'react';
import { motion } from 'framer-motion';
import '../styles/WorkingStatusHeader.css';

function WorkingStatusHeader() {
    return (
        <motion.div
            className="working-status-header"
            initial={{ y: -100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
        >
            <p>🚧 This site is currently under development! 🚀 Stay tuned for updates.</p>
        </motion.div>
    );
}

export default WorkingStatusHeader;
