import React from 'react';
import { Link } from 'react-router-dom';
import { MdSpaceDashboard, MdFolder, MdImportExport } from 'react-icons/md';
import '../styles/Sidebar.css';
import {RiRobot3Line} from "react-icons/ri";
import {FaMoneyCheck} from "react-icons/fa6";

const Sidebar = () => {
    return (
        <aside className="sidebar">
            <ul className="sidebar-menu">
                <li><Link to="/dashboard"><MdSpaceDashboard size="25px" /> Dashboard</Link></li>
                <li><Link to="/assistant"><RiRobot3Line  size="25px" /> Assistants</Link></li>
                <li><Link to="/files"><MdFolder size="25px" /> Files</Link></li>
                <li><Link to="/exports"><MdImportExport size="25px" /> Exports</Link></li>
                <li><Link to="/pricing"><FaMoneyCheck size="25px" /> Pricing</Link></li>
            </ul>
        </aside>
    );
};

export default Sidebar;
