import React, {useEffect, useState} from "react";
import '../styles/Pricing.css'
import {getPricingPlans, getSubscription} from "../clients/farmland";
import {ConvertIfUnlimited} from "../helper/converters";
import Spinner from "./Spinner";
import {color} from "framer-motion";

function Pricing () {
    const [isSubscribing, setIsSubscribing] = useState(false);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [pricingPlans, setPricingPlans] = useState([]);
    const [subscriptionDetail, setSubscriptionDetail] = useState({});
    const [subscriptionHistory, setSubscriptionHistory] = useState([]);
    const [currentPlan, setUserCurrentPlan] = useState( {});
    const [showSubscriptionHistory, setShowSubscriptionHistory] = useState( false);

    useEffect(() => {
        const fetchPricingPlans = async () => {
            setIsLoading(true);
            const { items, error } = await getPricingPlans();
            items.sort((a, b) => a.Price - b.Price);
            console.log(items);
            if (error) {
                setError(error);
            } else {
                setPricingPlans(items);
            }
            setIsLoading(false);
        };

        fetchPricingPlans().then();
    }, []);

    useEffect(() => {
        const fetchSubscriptionDetails = async () => {
            if (pricingPlans.length === 0) return;

            const { items, error } = await getSubscription();
            console.log(items);
            if (error !== "") {
                setError(error);
            } else {
                setSubscriptionDetail(items.subscription);
                setSubscriptionHistory(items.subscriptionHistory);
                console.log(pricingPlans);
                console.log(items);

                const plan = pricingPlans.filter(
                    (i) => i.Id === items.subscription.SubscriptionPlanId
                )[0] ?? { messageCredit: 0, name: "" };

                setUserCurrentPlan({
                    messageCredit: ConvertIfUnlimited(plan.MessageCredit),
                    assistantCredit: plan.AssistantCredit,
                    name: plan.Name,
                });
            }
        };

        fetchSubscriptionDetails().then();
    }, [pricingPlans]);

    const handleCancel = () => {
        setIsSubscribing(false)
        setSelectedSubscription(null)
    }
    const handleNext = () => {
        setIsSubscribing(true)
    }

    return (
        isLoading ? (<Spinner />) : (
            <div className="pricing-container">
                <div className="pricing-container-header">
                    <h1>Pricing</h1>
                    <button className="subscribe-button" onClick={handleNext}>
                        Upgrade Subscription
                    </button>
                    <p><span className="subscribe-history-link" onClick={() => { setShowSubscriptionHistory(true)
                    }}>Subscription History</span></p>
                </div>
                <div className="current-subscription-container">
                    <h2>Your Subscription Tier</h2>
                    <h3 style={{ color: '#ffedb7' }}>{currentPlan.name}</h3>                    <div className="current-usage-info">
                        <h2>Your Usage Information According to Your Plan</h2>
                        <div className="usage-item">
                            <span>Assistant</span>
                            <span>Remaining usage: {subscriptionDetail.RemainingAssistants}/{currentPlan.assistantCredit}</span>
                        </div>
                        <div className="usage-item">
                            <span>Message</span>
                            <span>Remaining usage for this month: {ConvertIfUnlimited(subscriptionDetail.RemainingMessages)}/{currentPlan.messageCredit}</span>
                        </div>
                    </div>
                </div>
                <div className="pricing-plans-container">
                    {pricingPlans.map((plan) => (
                        <div key={plan.Id} className="plan-card">
                            <h3>{plan.Name}</h3>
                            <p className="price">${plan.Price}</p>
                            <p>/{plan.Frequency}</p>
                            <p>The {plan.Name} plan
                                contains {plan.MessageCredit === -1 ? 'unlimited' : plan.MessageCredit} messages per
                                month, {plan.AssistantCredit} conversation assistants and {plan.FileCredit} file
                                uploads.</p>
                        </div>
                    ))}
                </div>
                <CreditTable pricingPlans={pricingPlans}/>
                {isSubscribing &&
                    <div className="subscription-modal">
                        <h2>Plan a subscription</h2>
                        <p>Choose the best plan that suits your needs and get started today!</p>
                        <div className="plan-options">
                            {pricingPlans.filter(i => i.Price !== 0 && i.Id !== subscriptionDetail.SubscriptionPlanId).map((plan) => (
                                <button
                                    key={plan.Id}
                                    className="plan-btn"
                                    onClick={() => setSelectedSubscription(plan)}
                                >
                                    {plan.Name} Plan - {plan.Price}/{plan.Frequency}
                                </button>
                            ))}
                        </div>
                        {
                            (selectedSubscription != null) && <div className="selected-subscription">
                                <p>{selectedSubscription.Name} plan is selected. <br/>To continue the subscription process,
                                    please click Pay
                                </p>
                            </div>
                        }
                        <div className="modal-buttons">
                            <button className="cancel-button" onClick={handleCancel}>
                                Cancel
                            </button>
                            <button className="next-button" onClick={handleNext}>
                                Pay
                            </button>
                        </div>
                    </div>
                }
                {showSubscriptionHistory && (
                    <div className="subscription-history-modal">
                        <h2>Subscription History</h2>
                        <div className="subscription-history-list">
                            {subscriptionHistory.length > 0 ? (
                                <table>
                                    <thead>
                                    <tr>
                                        <th>Plan</th>
                                        <th>Subscription Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {subscriptionHistory.map((history) => (
                                        <tr key={history.Id}>
                                            <td>{pricingPlans.filter(i => i.Id === history.SubscriptionPlanId)[0].Name}</td>
                                            <td>{history.CreatedAt}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            ) : (
                                <p>No subscription history available.</p>
                            )}
                        </div>
                        <button className="close-button" onClick={() => setShowSubscriptionHistory(false)}>
                            Close
                        </button>
                    </div>
                )}
            </div>
        )
    )
}

function CreditTable({pricingPlans}) {
    return (<div className="resources-container">
            <h2>Resources</h2>
            <table className="resources-table">
                <thead>
                <tr>
                    <th>Plans</th>
                    {
                        pricingPlans.map((plan) => (
                            <th>{plan.Name}</th>
                        ))
                    }
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Conversational Assistants</td>
                    {
                        pricingPlans.map((plan) => (
                            <td>{plan.AssistantCredit}</td>
                        ))
                    }
                </tr>
                <tr>
                    <td>Files</td>
                    {
                        pricingPlans.map((plan) => (
                            <td>{plan.FileCredit}</td>
                        ))
                    }
                </tr>
                <tr>
                    <td>Messages Per Month</td>
                    {
                        pricingPlans.map((plan) => (
                            <td>{plan.MessageCredit === -1 ? '∞' : plan.MessageCredit}</td>
                        ))
                    }
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default Pricing
