import React, {useState} from 'react';
import {updateUser} from "../clients/farmland";
import Notification from "./Notification";

function Settings() {
    const [apiKey, setApiKey] = useState('');
    const [notification, setNotification] = useState(null);


    const handleSave = async () => {
        await updateUser(apiKey)
        setNotification({
            message: 'API key successfully saved',
            type: 'success'
        })
    };

    const handleCloseNotification = () => {
        setNotification(null);
    };

    return (
        <div className="settings-container">
            <h1>Settings</h1>
            <div className="api-key-container">
                <h2>Account configuration</h2>
                <p>This project is supported by the hand of the OpenAI LLM's and it is required OpenAI's own API key to
                    launch. It is possible to create an API Key <a href="https://platform.openai.com/account/api-keys"
                                                                   target="_blank" rel="noopener noreferrer">here</a> if
                    there isn't set yet.</p>
                <p>Besides, it is mandatory to add <a href="https://platform.openai.com/account/billing" target="_blank"
                                                      rel="noopener noreferrer">a payment method</a> to the OpenAI
                    account
                    to increase rate limits.</p>

                <label htmlFor="apiKey">OpenAI Global API Key</label>
                <input
                    type="text"
                    id="apiKey"
                    value={apiKey}
                    onChange={(e) => setApiKey(e.target.value)}

                />
                <div className="button-container">
                    <button className="save-button" onClick={handleSave}>Save</button>
                </div>
            </div>
            {notification && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={handleCloseNotification}
                />
            )}
        </div>
    );
}

export default Settings;
