import React, {useEffect, useState} from 'react';
import '../styles/Files.css';
import {MdAdd, MdDelete} from "react-icons/md";
import UploadFileModal from './UploadFileModal';
import {RiRobot3Line} from "react-icons/ri";
import {deleteFile, listFiles} from "../clients/farmland";
import Spinner from "./Spinner";
import ErrorModal from "./ErrorModal";
import { paginate } from "../helper/pagination"
import Notification from "./Notification";


const Files = () => {
    const [files, setFiles] = useState([]);
    const [isAdding, setIsAdding] = useState(false);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [notification, setNotification] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;

    const { paginatedItems: paginatedFiles, totalPages } = paginate(files, currentPage, pageSize);

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage((prev) => prev - 1);
    };

    useEffect(() => {
        if (!isDataLoaded) {
            const fetchAssistants = async () => {
                setIsLoading(true);
                const {files, error} = await listFiles();
                setFiles(files);
                if (error !== "") {
                    setError(error);
                }
                setIsDataLoaded(true);
                setIsLoading(false);
            };

            fetchAssistants().then();
        }
    }, [isDataLoaded]);

    const handleUpload = (newFile) => {
        setNotification({
            message: 'File successfully added',
            type: 'success'
        })
    };

    const handleErrorOk = () => {
        setError('');
    };

    const handleDelete = async (id) => {
        await deleteFile(id)
        await refreshFileList()
        setNotification({
            message: 'File successfully deleted',
            type: 'success'
        })
    };

    const refreshFileList = async () => {
        const { files, error } = await listFiles();
        setFiles(files);
        if (error !== "") {
            setError(error);
        }
    }

    return (
        <div className="file-container">
            <div className="file-container-header">
                <h1>Files</h1>
                <button className="create-file-button" onClick={() => {setIsAdding(true)}}>
                    + Add File
                </button>
            </div>
            {
                isLoading ? (
                    <Spinner />
                ) : files?.length === 0 ? (
                    <p className="no-files">No files uploaded yet.</p>
                ) : (
                    <>
                        <div className="file-list">
                            {paginatedFiles.map((file) => (
                                <div key={file.id} className="file-card">
                                    <div className="file-details">
                                        <p>
                                            <strong>Name:</strong> {file.filename} <br />
                                            <strong>Size:</strong> {(file.bytes / (1024 * 1024)).toFixed(2)}MB <br />
                                            <strong>CreatedAt:</strong> {new Date(file.created_at * 1000).toLocaleString()}
                                        </p>
                                    </div>
                                    <div className="file-card-buttons">
                                        <button className="add-to-assistant-button">
                                            <MdAdd />
                                            <RiRobot3Line />
                                        </button>
                                        <button className="delete-button" onClick={() => handleDelete(file.id)}>
                                            <MdDelete />
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <div className="pagination">
                            <button
                                onClick={handlePreviousPage}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            <span>
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </div>
                    </>
                )
            }

            {isAdding && (
                <div className="files-upload-file-modal">
                    <UploadFileModal
                        onClose={() => setIsAdding(false)}
                        onUpload={(result) => {
                            handleUpload(result);
                            setNotification({
                                message: 'File uploaded successfully',
                                type: 'success',
                            });
                        }}
                    />
                </div>
            )}

            {notification && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={() => setNotification(null)}
                />
            )}

            {
                error !== '' && (
                    <div className="files-error-modal">
                    <ErrorModal
                            handleErrorOk={handleErrorOk}
                            errorMessage={error}
                        />
                    </div>
                )
            }
        </div>
    );
};

export default Files;
