import React from "react";
import '../styles/ConfirmModal.css'

const ConfirmModal = ({handleCancel, handleSave, savePH, confirmationText}) => {

    return (
        <div className="confirm-requested-container">
            <div className="confirm-requested-modal">
                <h2>{confirmationText}</h2>
                <div className="confirm-modal-buttons">
                    <button className="cancel-button" onClick={handleCancel}>
                        Cancel
                    </button>
                    <button className="save-button" onClick={handleSave}>
                        {savePH}
                    </button>
                </div>
            </div>
        </div>
    )

}

export default ConfirmModal