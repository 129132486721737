import React from "react";
import '../styles/ErrorModal.css';

const ErrorModal = ({ handleErrorOk, errorMessage }) => {

    return (
        <div className="error-modal-container">
            <div className="error-modal">
                <h2>Error Occurred</h2>
                <p>{errorMessage}</p>
                <div className="error-modal-buttons">
                    <button className="error-ok-button" onClick={handleErrorOk}>
                        Okay
                    </button>
                </div>
            </div>
        </div>
    );

};

export default ErrorModal;
