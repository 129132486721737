import React, {useState} from 'react';
import '../styles/UploadFileModal.css';
import {getCookie} from "../helper/cookieHandler";
import Spinner from "./Spinner";
import {addFilesToVectorStore} from "../clients/farmland";
import Notification from "./Notification";

const ServerBaseURL = process.env.REACT_APP_SERVER_BASE_URL

const UploadFileModal = ({onClose, vectorStoreId}) => {
    const [newFile, setNewFile] = useState(null);
    const [dragActive, setDragActive] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [notification, setNotification] = useState(null);

    const handleFileSelect = (e) => {
        const file = e.target.files[0];
        if (file) {
            setNewFile(file);
        }
    };

    const handleFileDrop = (e) => {
        e.preventDefault();
        setDragActive(false);
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            setNewFile(e.dataTransfer.files[0]);
            e.dataTransfer.clearData();
        }
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragActive(true);
    };

    const handleDragLeave = () => {
        setDragActive(false);
    };

    const handleCloseNotification = () => {
        setNotification(null);
    };

    const handleUpload = async () => {
        setIsLoading(true)
        const token = getCookie('token')
        if (newFile) {
            const formData = new FormData();
            formData.append("file", newFile);

            try {
                const response = await fetch(`${ServerBaseURL}/file`, {
                    method: 'POST',
                    body: formData,
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                });

                const responseData = await response.json();
                if (vectorStoreId !== undefined) {
                    const fileIds = [responseData?.data?.id];
                    await addFilesToVectorStore(fileIds, vectorStoreId);
                    console.log('Files added to vector store successfully');
                }

                onClose();
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
        setIsLoading(false)
        setNotification({
            message: 'File successfully added',
            type: 'success'
        })
    };

    return (
        isLoading ? (<Spinner/>) : (
            <div className="upload-file-modal" onDragOver={handleDragOver} onDragLeave={handleDragLeave}
                 onDrop={handleFileDrop}>
                <div className="upload-file-modal-content">
                    <h2>Upload a new file</h2>
                    <div
                        className={`drop-zone ${dragActive ? "active" : ""}`}
                        onClick={() => document.getElementById('fileInput').click()}
                    >
                        {newFile ? (
                            <p>{newFile.name}</p>
                        ) : (
                            <p>Drag & drop a file here, or click to select</p>
                        )}
                    </div>
                    <input
                        type="file"
                        id="fileInput"
                        style={{display: 'none'}}
                        onChange={handleFileSelect}
                    />
                    <div className="modal-buttons">
                        <button className="cancel-button" onClick={onClose}>Cancel</button>
                        {newFile && <button className="upload-button" onClick={handleUpload}>Upload</button>}
                    </div>
                </div>

                {notification && (
                    <Notification
                        message={notification.message}
                        type={notification.type}
                        onClose={handleCloseNotification}
                    />
                )}
            </div>

        )
    );
};

export default UploadFileModal;
