import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FaPaperPlane, FaSpinner } from 'react-icons/fa';
import corporateIcon from '../assets/icons/sgreen.png';
import ReactMarkdown from 'react-markdown';
import '../styles/Conversation.css'

const baseURL = process.env.REACT_APP_SERVER_BASE_URL;
const assistantId = "asst_GPEpEpozl3AzPzfCqO5TPj0G";

const Conversation = () => {
    const [messages, setMessages] = useState([{ role: 'assistant', content: 'Merhaba, size nasıl yardımcı olabilirim?' }]);
    const [userInput, setUserInput] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [threadCreated, setThreadCreated] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const savedToken = sessionStorage.getItem('token');
        const savedThreadId = sessionStorage.getItem('threadId');

        if (savedToken) {
            setIsAuthenticated(true);
        }

        if (savedThreadId) {
            setThreadCreated(true);
        }
    }, []);

    const createThread = async (token) => {
        try {
            const threadResponse = await axios.post(`${baseURL}/conversation/thread/create`, {}, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                }
            });
            const newThreadId = threadResponse.data.data;
            sessionStorage.setItem('threadId', newThreadId);
            setThreadCreated(true);
        } catch (error) {
            console.log(`Error occurred trying to create a thread: ${error}`)
        }
    };

    const handleSendMessage = async () => {
        if (userInput.trim() === '') return;
        setLoading(true);

        const token = sessionStorage.getItem('token');
        const threadId = sessionStorage.getItem('threadId');
        if (!threadCreated) {
            await createThread(token);
        }

        const userMessage = { role: 'user', content: userInput };
        setMessages([...messages, userMessage]);

        const message = userInput
        setUserInput('')

        try {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            const response = await axios.post(`${baseURL}/conversation/message/create`, {
                threadId: threadId || sessionStorage.getItem('threadId'),
                assistantId: assistantId,
                message: {
                    "role": "user",
                    "content": message
                },
            }, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                }
            });

            const assistantMessage = { role: 'assistant', content: response.data.data };
            setMessages((prevMessages) => [...prevMessages, assistantMessage]);
        } catch (error) {
            console.log(`Error occurred while sending message: ${error}`)
        } finally {
            setLoading(false)
        }
    };

    const handleCancelMessage = () => {
        setLoading(false);
    };

    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            if (loading) {
                handleCancelMessage();
            } else {
                await handleSendMessage();
            }
        }
    };

    return (
        <div className="chat-container">
            <div className="chat-messages">
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`message ${message.role === 'user' ? 'user-message' : 'assistant-message'}`}
                    >
                        {message.role === 'assistant' ? (
                            <ReactMarkdown>{message.content}</ReactMarkdown>
                        ) : (
                            message.content
                        )}
                    </div>
                ))}
            </div>
            <div className="input-container">
                <input
                    type="text"
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onKeyDown={handleKeyDown}
                    placeholder="Lütfen bir mesaj yazın..."
                />
                <button
                    className="send-button"
                    onClick={loading ? handleCancelMessage : handleSendMessage}
                >
                    {loading ? <FaSpinner className="spinner" size={24} /> : <FaPaperPlane size={24} />} {}
                </button>
            </div>
            <footer className="support-footer">
                <p>
                    Bu asistan Sgreen <img
                    src={corporateIcon}
                    alt="sgreen-icon"
                    style={{
                        width: '20px',
                        height: '20px',
                        marginRight: '0px',
                        verticalAlign: 'middle',
                    }}
                /> tarafından desteklenmektedir.
                </p>
                <p className="disclaimer">
                    Önemli bilgileri kontrol edin.
                </p>
            </footer>
        </div>
    );
};

export default Conversation;
