import React from "react";
import '../styles/Spinner.css'

const Spinner = () => (
    <div className="spinner-loading">
        <div className="loader"></div>
    </div>
);

export default Spinner
