import React, {useEffect} from 'react';
import '../styles/Layout.css';
import {Outlet, useNavigate} from 'react-router-dom';
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import {getCookie} from "../helper/cookieHandler";
import axios from "axios";

const ServerBaseURL = process.env.REACT_APP_SERVER_BASE_URL

const Layout = () => {
    const navigate = useNavigate()

    useEffect(() => {
        const verifyToken = async () => {
            const token = getCookie('token')
            if (!token) {
                navigate('/login')
                return
            }

            try {
                await axios.post(`${ServerBaseURL}/authorization/verify`, {}, {
                    headers: {Authorization: `Bearer ${token}`}
                })

            } catch (error) {
                navigate('/login')
            }
        }

        verifyToken().then()
    }, [navigate]);
    return (
        <div className="layout">
            <Header />
            <div className="layout-container">
                <Sidebar />
                <div className="outlet">
                    <Outlet />
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Layout;
