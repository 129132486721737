export const DownloadHtml = ({src}) => {
    const htmlContent = `
      <iframe
        src="${src}"
        width="100%"
        height="100%"
        style="border:none;"
        title="Conversation Component"></iframe>
    `;
    const blob = new Blob([htmlContent], {type: 'text/html'});
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'conversation_component.html';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};