import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import Login from './components/Login.js'
import Signup from './components/Signup.js'
import './styles/Auth.css';
import './styles/Sidebar.css';
import './styles/Dashboard.css';
import './styles/Header.css';
import './styles/Settings.css';
import Dashboard from "./components/Dashboard";
import Layout from "./components/Layout";
import Assistant from "./components/Assistant";
import Settings from "./components/Settings";
import Files from "./components/Files";
import {RiProgress3Line} from "react-icons/ri";
import Google from "./components/Google";
import HomePage from "./components/HomePage";
import ConversationGeneric from "./components/ConversationGeneric";
import Conversation from "./components/Conversation";
import Pricing from "./components/Pricing";
import ExportMessages from "./components/ExportMessages";

function App() {
  return (
      <Router>
        <Routes>
          <Route
              path="/welcome"
              element={
                <div style={{ textAlign: 'center', padding: '20px' }}>
                  <h1>Merhaba,</h1>
                  <p>Giriş yapmak için <a href="/login">buraya tıklayın.</a></p>
                </div>
              }
          />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/" element={<HomePage />} />
          <Route path="/" element={<Layout/>}>
              <Route path="dashboard" element={<Dashboard/>} />
              <Route path="assistant" element={<Assistant/>} />
              <Route path="settings" element={<Settings/>} />
              <Route path="files" element={<Files/>} />
              <Route path="pricing" element={<Pricing/>} />
              <Route path="exports" element={<ExportMessages/>}/>
          </Route>
            <Route path="/dashboard" element={<Dashboard/>}/>
            <Route path="/assistant" element={<Assistant/>}/>
            <Route path="/settings" element={<Settings/>}/>
            <Route path="/files" element={<Files/>}/>
            <Route path="/google" element={<Google/>}/>
            <Route path="/home" element={<HomePage/>}/>
            <Route path="/exports" element={<ExportMessages/>}/>
            <Route path="/conversation" element={<Conversation />} />
            <Route path="/conversation/:assistantId" element={<ConversationGeneric />} />
        </Routes>
      </Router>
  );
}

export default App;
