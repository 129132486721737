import React, { useState } from 'react';
import '../styles/Auth.css';
import axios from "axios";
import {FaSpinner} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {sendMail} from "../clients/farmland";

const ServerBaseURL = process.env.REACT_APP_SERVER_BASE_URL
const Signup = () => {
    const navigate = useNavigate()
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        if (!validateEmail(email)) {
            setError("Invalid email format.");
            setSuccess('');
            return;
        }

        if (password.length < 8) {
            setError("Password must be at least 8 characters long.");
            setSuccess('');
            return;
        }

        if (password !== confirmPassword) {
            setError("Passwords do not match!");
            setSuccess('');
            return;
        }

        setError('');
        try {
            const response = await axios.post(`${ServerBaseURL}/authentication/signup`, {
                email,
                password,
            });

            setSuccess('Registration successful. Welcome aboard!');
            setEmail('');
            setPassword('');
            setConfirmPassword('');

            sessionStorage.setItem('token', response.data.token);

            await sendMail('welcome')
            navigate('/dashboard')
        } catch (err) {
            setIsSubmitting(false)
            if (err.response !== undefined){
                setError(err.response.data.message);
            } else {
                setError("Something unexpected happened, try again later!")
            }
        }
    };

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    return (
        <div className="auth-container">
            <div className="chatbot-icon-container">
                <img src={'/factory-head.png'} alt="" style={{width: '100px', height: '100px'}}/>
            </div>
            <h2 align="center" className="typing-animation">Welcome to Assistant Factory</h2>
            {error && <div className="error-popup">{error}</div>}
            {success && <div className="success-popup">{success}</div>}
            <form onSubmit={handleSubmit}>
                <label>Email</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <label>Password</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <label>Confirm Password</label>
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
                <button type="submit" className="auth-button" disabled={isSubmitting}>
                    {isSubmitting ? (
                        <div className="spinner">
                            <FaSpinner className="spinner-icon" size="20px"/>
                        </div>
                    ) : (
                        "Sign Up"
                    )}
                </button>
                <div className="signup-link-container">
                    <p>Already have an account? <span className="signup-link"
                                                     onClick={() => navigate('/login')}>Login</span></p>
                </div>
            </form>
        </div>
    );
};

export default Signup;
