import React, {useState, useEffect} from 'react';
import {exportAssistantMessages, getAssistants} from "../clients/farmland";
import '../styles/ExportMessages.css';
import Notification from "./Notification";

const ExportMessages = () => {
    const [assistants, setAssistants] = useState([]);
    const [selectedAssistant, setSelectedAssistant] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [notification, setNotification] = useState(null);

    useEffect(() => {
        const fetchAssistants = async () => {
            try {
                const response = await getAssistants();
                setAssistants(response.assistants || []);
            } catch (err) {
                setNotification({type: 'error', message: 'Failed to load assistants'})
            }
        };

        fetchAssistants().then();
    }, []);

    const handleExport = async () => {
        if (!selectedAssistant || !startDate || !endDate) {
            setNotification({type: 'error', message: 'Please select an assistant and provide a valid date range'})
            return;
        }

        setIsLoading(true);
        try {
            const response = await exportAssistantMessages(selectedAssistant, startDate, endDate);
            console.log()
            if (response.error !== '') {
                setNotification({type: 'error', message: 'For given date range and assistant, there are no any messages created'})
            } else {
                setNotification({type: 'success', message: 'Messages exported successfully'})
            }
        } catch (err) {
            setNotification({type: 'error', message: 'For given date range and assistant, there are no any messages created'})
        } finally {
            setIsLoading(false);
        }
    };

    const handleCloseNotification = () => {
        setNotification(null);
    };

    return (
        <div className="exports-container">
            <h1>Exports</h1>
            <div className="export-messages">
                <div className="export-container">
                    <div className="export-container-header">
                        <h2 className="export-title">Download Assistant Messages</h2>
                        <div className="tooltip-container">
                            <i className="tooltip-icon">i</i>
                            <span className="tooltip-text">
                        Select an assistant and a date range, then click "Export Messages" to download the data.
                    </span>
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="assistant-select" className="form-label">
                            Select Assistant:
                        </label>
                        <select
                            id="assistant-select"
                            className="form-select"
                            value={selectedAssistant}
                            onChange={(e) => setSelectedAssistant(e.target.value)}
                        >
                            <option value="">-- Choose an Assistant --</option>
                            {assistants.map((assistant) => (
                                <option key={assistant.id} value={assistant.id}>
                                    {assistant.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group date-group">
                        <label htmlFor="start-date" className="form-label">
                            Start Date:
                        </label>
                        <input
                            type="date"
                            id="start-date"
                            className="form-input"
                            value={startDate}
                            onChange={(e) => setStartDate(e.target.value)}
                        />
                    </div>
                    <div className="form-group date-group">
                        <label htmlFor="end-date" className="form-label">
                            End Date:
                        </label>
                        <input
                            type="date"
                            id="end-date"
                            className="form-input"
                            value={endDate}
                            onChange={(e) => setEndDate(e.target.value)}
                        />
                    </div>
                    <button
                        onClick={handleExport}
                        className={`export-button ${isLoading ? "disabled" : ""}`}
                        disabled={!selectedAssistant || !startDate || !endDate || isLoading}
                    >
                        {isLoading ? "Exporting..." : "Export Messages"}
                    </button>
                </div>
            </div>

            {notification && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={handleCloseNotification}
                />
            )}
        </div>
    );
};

export default ExportMessages;