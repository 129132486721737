export const paginate = (items, currentPage, pageSize) => {
    const totalItems = items.length;
    const totalPages = Math.ceil(totalItems / pageSize);

    if (currentPage > totalPages) {
        currentPage = totalPages;
    }

    const startIndex = (currentPage - 1) * pageSize;
    const paginatedItems = items.slice(startIndex, startIndex + pageSize);

    return {
        currentPage,
        totalPages,
        paginatedItems,
    };
};
