import React, {useEffect, useRef, useState} from 'react';
import { motion, useInView } from 'framer-motion';
import '../styles/HomePage.css';
import Footer from "./Footer";
import { RiRobot3Line } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { PiOpenAiLogo } from "react-icons/pi";
import { FiTool } from "react-icons/fi";
import { BiSolidCustomize } from "react-icons/bi";
import { TbPackageExport } from "react-icons/tb";
import WorkingStatusHeader from "./WorkingStatusHeader";
import {getPricingPlans} from "../clients/farmland";
import Spinner from "./Spinner";
function HomePage() {
    const navigate = useNavigate();

    const [pricingPlans, setPricingPlans] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    sessionStorage.setItem('token', "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiIwNDEyNTRlZS0yMmNjLTRlOGMtODZlNy1jNzNlY2NhOTQ1NDIiLCJhcGlLZXkiOiJzay1wcm9qLTlTNThzR0tzRUtlWTBoVHloanAycENHN21yQWs2UHpjSVhFSlNPTG9fRUdJV0N1aHp0WW9mbjBIYk9uQURMSlZnLW9xTnhyRU0xVDNCbGJrRkp6NG9zMm8yVzBRZEtER2xhMGxMS1hSMlM0eWxid0JnYUtaTC05UEtTMDc5YS1VZTM0bjZFZUdUc3hwLTdWM3JoSlRHeU9MdzJFQSIsImlhdCI6MTczMjQ2Mzk3MCwiZXhwIjoxNzM2NzgzOTcwfQ.DPcOzNO707M_g0T7pFsBxH2qqT9X-14G1U9euTkdo6s");

    useEffect(() => {
        const fetchPricingPlans = async () => {
            setIsLoading(true);
            const { items, error } = await getPricingPlans();
            items.sort((a, b) => a.Price - b.Price);
            console.log(items);
            if (error) {
            } else {
                setPricingPlans(items);
            }
            setIsLoading(false);
        };

        fetchPricingPlans().then();
    }, []);

    const handleSignup = () => {
        navigate('/signup');
    };
    const handleLogin = () => {
        navigate('/login');
    };
    const handleLogoClick = () => {
        navigate('/home');
    };

    return (
        <div className="home-container">
            <WorkingStatusHeader/>
            <header className="home-header">
                <div className="logo-container" onClick={handleLogoClick}>
                    <RiRobot3Line size="30px"/>
                    <p>Assistant Factory</p>
                </div>
                <div className="home-header-buttons">
                    <button className="login-button" onClick={handleLogin}>
                        <p>Login</p>
                    </button>
                    <button className="signup-button" onClick={handleSignup}>
                        <p>Signup</p>
                    </button>
                </div>
            </header>
            <Section
                text="Welcome to Assistant Factory!"
                subtitle="Create your own conversational assistant within seconds for your business application"
                imageShort='/factory-head.png'
                imageSrc='/dashboard.png'
                style={{
                    padding: '50px 0',
                    textAlign: 'center',
                }}
            />
            <div className="homepage-blocks-container">
                {[
                    {
                        title: "Fully Customized Assistant",
                        description:
                            "Assistant Factory ensures business owners to create their own conversational assistants with training their own data",
                        logo: <BiSolidCustomize size="28" style={{marginRight: "8px"}}/>,
                    },
                    {
                        title: "Easy to implement",
                        description:
                            "Assistant Factory focuses on easily implementable. Business owners can create, manage and deploy own assistants within minutes.",
                        logo: <FiTool size="28" style={{marginRight: "8px"}}/>,
                    },
                    {
                        title: "Several export methods satisfied",
                        description:
                            "Business owners can export assistants to their own application with several assistant concepts. Conversation flow be on console, or can be given as just an assistant icon.",
                        logo: <TbPackageExport size="28" style={{marginRight: "8px"}}/>,
                    },
                    {
                        title: "OpenAI Support",
                        description:
                            "Assistant Factory uses the OpenAI's LLMs'. This guarantees the conversation's accuracy will be OpenAI marked.",
                        logo: <PiOpenAiLogo size="28" style={{marginRight: "8px"}}/>,
                    },
                ].map((block, index) => (
                    <motion.div
                        key={index}
                        className="homepage-block"
                        initial={{opacity: 0, y: 50}}
                        whileInView={{opacity: 1, y: 0}}
                        transition={{duration: 0.6, delay: index * 0.2}}
                        viewport={{once: false}}
                        exit={{opacity: 0, y: 50}}
                    >
                        <h3>
                            {block.logo && block.logo} {/* Render the logo if it exists */}
                            {block.title}
                        </h3>
                        <p>{block.description}</p>
                    </motion.div>
                ))}
            </div>
            <motion.div
                className="home-conversation-section"
                initial={{opacity: 0, y: 50}}
                whileInView={{opacity: 1, y: 0}}
                transition={{duration: 0.6, delay: 0.8}}
                viewport={{once: false}}
                exit={{opacity: 0, y: 50}}
            >
                <h2>You can try Assistant Factory assistant!</h2>
                <div className="home-conversation-container">
                    <iframe src="https://www.assistant-factory.com/conversation/asst_ubuCxRCPlmCaXrJQhFT6Urfv"
                            width="100%"
                            height="600" frameBorder="0" allowFullScreen></iframe>
                </div>
            </motion.div>
            <Section
                text="Assistant Factory gives several super easy implementation methods to business owners!"
                imageSrc='/export.png'
                style={{
                    padding: '50px 0',
                    textAlign: 'center',
                }}
            />
            {
                isLoading ? (
                    <Spinner/>
                ) :
                    <div className="pricing-plans">
                        <h1 style={{color:"#FFFFFFA8",textAlign:"center", fontSize:"45px"}}>Pricing</h1>
                        <div className="pricing-plans-container">
                            {pricingPlans.map((plan) => (
                                <div key={plan.Id} className="plan-card">
                                    <h3>{plan.Name}</h3>
                                    <p className="price">${plan.Price}</p>
                                    <p>/{plan.Frequency}</p>
                                    <p>The {plan.Name} plan
                                        contains {plan.MessageCredit === -1 ? 'unlimited' : plan.MessageCredit} messages
                                        per
                                        month, {plan.AssistantCredit} conversation assistants and {plan.FileCredit} file
                                        uploads.</p>
                                </div>
                            ))}
                        </div>
                    </div>
            }
            <Footer/>
        </div>
    );
}

function Section({text, subtitle, imageShort, imageSrc, style}) {
    const ref = useRef(null);
    const isInView = useInView(ref, {threshold: 0.2});

    return (
        <motion.div
            ref={ref}
            className="section"
            style={style}
            initial={{opacity: 0, y: 50}}
            animate={isInView ? {opacity: 1, y: 0} : {opacity: 0, y: 50}}
            transition={{duration: 0.6}}
            viewport={{once: false}}
            exit={{opacity: 0, y: 50}}
        >
            {imageShort && <img src={imageShort} alt="" className="section-image-short"/>}
            <h2>{text}</h2>
            {subtitle && <p className="section-subtitle">{subtitle}</p>}
            {imageSrc && <img src={imageSrc} alt="" className="section-image"/>}
        </motion.div>
    );
}

export default HomePage;
