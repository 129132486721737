import React, { useEffect, useState } from 'react';
import '../styles/Dashboard.css';
import { getConversationMessagesWeekly, getDashboardSummary } from "../clients/farmland";
import Spinner from "./Spinner";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';
import Notification from "./Notification";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const Dashboard = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState('');
    const [dashboardSummary, setDashboardSummary] = useState({
        assistantCount: 0,
        conversationCount: 0,
        distinctThreadCount: 0,
        fileCount: 0,
    });
    const [weeklyCounts, setWeeklyCounts] = useState([]);
    const [labels, setLabels] = useState([]);
    const [notification, setNotification] = useState({
        message: 'Exports page is out, you can export your conversation history now!',
        type: 'info'
    });

    useEffect(() => {
        const generateDynamicLabels = () => {
            const today = new Date();
            const days = [];

            for (let i = 0; i < 7; i++) {
                const day = new Date(today);
                day.setDate(today.getDate() - i);
                days.unshift(day.toLocaleDateString('en-US', { weekday: 'short', day: 'numeric' }));
            }

            setLabels(days.reverse());
        };

        generateDynamicLabels();

        const fetchDashboardSummary = async () => {
            setIsLoading(true);
            try {
                const { summary, error } = await getDashboardSummary();
                if (error) {
                    setError(error);
                } else {
                    setDashboardSummary(summary);
                }
            } catch (err) {
                setError('An error occurred while fetching dashboard summary.');
            } finally {
                setIsLoading(false);
            }
        };

        const fetchWeeklyCounts = async () => {
            try {
                const { messageCounts, error } = await getConversationMessagesWeekly();
                if (error) {
                    setError(error);
                } else if (messageCounts) {
                    setWeeklyCounts([
                        messageCounts.Day6,
                        messageCounts.Day5,
                        messageCounts.Day4,
                        messageCounts.Day3,
                        messageCounts.Day2,
                        messageCounts.Day1,
                        messageCounts.Day0,
                    ].reverse());
                }
            } catch (err) {
                setError('An error occurred while fetching weekly message counts.');
            }
        };

        const fetchData = async () => {
            await Promise.all([fetchDashboardSummary(), fetchWeeklyCounts()]);
        };

        fetchData().then();
    }, []);

    const chartData = {
        labels: labels,
        datasets: [
            {
                label: 'Message',
                data: weeklyCounts,
                backgroundColor: 'rgb(193,169,110)',
                borderColor: 'rgb(178,158,106)',
                borderWidth: 1,
            },
        ],
    };

    const chartOptions = {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Days',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Messages',
                },
                beginAtZero: true,
            },
        },
    };

    const handleCloseNotification = () => {
        setNotification(null);
    };

    return (
        <div className="dashboard">
            <h1>Dashboard</h1>
            {isLoading ? (
                <Spinner />
            ) : error ? (
                <div className="error-message">Error: {error}</div>
            ) : (
                <>
                    <div className="dashboard-cards">
                        <Card title="Total Conversation Assistants" value={dashboardSummary?.assistantCount || 0} />
                        <Card title="Total Files" value={dashboardSummary?.fileCount || 0} />
                        <Card title="Total Messages" value={dashboardSummary?.conversationCount || 0} />
                        <Card title="Total Threads" value={dashboardSummary?.distinctThreadCount || 0} />
                    </div>

                    <div className="daily-messages">
                        <h2>Weekly Message Counts</h2>
                        <Bar
                            key={weeklyCounts.join(',')}
                            data={chartData}
                            options={chartOptions}
                        />
                    </div>
                </>
            )}
            {notification && (
                <Notification
                    message={notification.message}
                    type={notification.type}
                    onClose={handleCloseNotification}
                />
            )}
        </div>
    );
};

const Card = ({ title, value }) => {
    return (
        <div className="card">
            <div className="card-title">{title}</div>
            <div className="card-value">{value}</div>
        </div>
    );
};

export default Dashboard;
