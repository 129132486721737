import React, { useState } from 'react';
import '../styles/Auth.css';
import axios from "axios";
import {FaSpinner} from "react-icons/fa";
import {useNavigate} from "react-router-dom";
import {sendMail} from "../clients/farmland";

const ServerBaseURL = process.env.REACT_APP_SERVER_BASE_URL

const Login = () => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true)

        try {
            console.log(ServerBaseURL)
            const response = await axios.post(`${ServerBaseURL}/authentication/login`, {
                email,
                password,
            });

            if (response?.data?.token !== undefined) {
                const token = response.data.token;

                sessionStorage.setItem('token', token);
            }

            setSuccess('Login successful. Welcome back!');
            setEmail('');
            setPassword('');
            setError('')

            await sendMail('welcome')

            navigate("/dashboard");
        } catch (err) {
            setIsSubmitting(false)
            console.log(err)
            if (err.response !== undefined) {
                setError(err.response.data.message);
            } else {
                setError("Something unexpected happened, try again later!")
            }
        }
    };

    return (
        <div className="auth-container">
            <div className="chatbot-icon-container">
                <img src={'/factory-head.png'} alt="" style={{ width: '100px', height: '100px' }} />
            </div>
            <h2 align="center" className="typing-animation">
                Welcome to Assistant Factory
            </h2>
            {error && <div className="error-popup">{error}</div>}
            {success && <div className="success-popup">{success}</div>}
            <form onSubmit={handleSubmit}>
                <label>Email</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <label>Password</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
                <button type="submit" className="auth-button" disabled={isSubmitting}>
                    {isSubmitting ? (
                        <div className="spinner">
                            <FaSpinner className="spinner-icon" size="20px" />
                        </div>
                    ) : (
                        "Login"
                    )}
                </button>
            </form>
            <div className="signup-link-container">
                <p>Don't have account yet? <span className="signup-link" onClick={() => navigate('/signup')}>Create</span></p>
            </div>
        </div>
    );
};

export default Login;
