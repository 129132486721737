import {getCookie} from "../helper/cookieHandler";
import axios from "axios";

const ServerBaseURL = process.env.REACT_APP_SERVER_BASE_URL
export const getAssistants = async () => {
    try {
        const token = getCookie('token')
        if (token === "") {
            return {assistants: [], error: "Authorization error"}
        }
        const response = await axios.get(`${ServerBaseURL}/assistant`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        console.log(response)

        const initialAssistants = response.data.data.map(i => ({
            id: i.id,
            name: i.name,
            instructions: i.instructions,
            model: i.model,
            vectorStoreId: i?.tool_resources?.file_search?.vector_store_ids[0] || ''
        }));

        return {assistants: initialAssistants, error: ""}
    } catch (err) {
        if (err.response !== undefined) {
            return {assistants: [], error: err.response.data.message}
        }
        return {assistants: [], error: "Something unexpected happened, try again later!"}
    }
}

export const createAssistant = async (name, instructions, vectorStoreId) => {
    try {
        const token = getCookie('token')
        if (token === "") {
            return {assistants: [], error: "Authorization error"}
        }

        await axios.post(`${ServerBaseURL}/assistant`, {
            name, instructions, vectorStoreId
        }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return {error: ""}
    } catch (err) {
        if (err.response !== undefined) {
            return {error: err.response.data.message}
        }
        return {error: "Something unexpected happened, try again later!"}
    }
}

export const updateAssistant = async (id, name, instructions) => {
    try {
        const token = getCookie('token')
        if (token === "") {
            return {assistants: [], error: "Authorization error"}
        }

        await axios.put(`${ServerBaseURL}/assistant`, {
            assistantId: id, name: name, instructions: instructions
        }, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });

        return {error: ""}
    } catch (err) {
        if (err.response !== undefined) {
            return {error: err.response.data.message}
        }
        return {error: "Something unexpected happened, try again later!"}
    }
}

export const deleteAssistant = async (id) => {
    try {
        const token = getCookie('token')
        if (token === "") {
            return {assistants: [], error: "Authorization error"}
        }

        console.log(id)
        await axios.delete(`${ServerBaseURL}/assistant?id=${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });

        return {error: ""}
    } catch (err) {
        if (err.response !== undefined) {
            return {error: err.response.data.message}
        }
        return {error: "Something unexpected happened, try again later!"}
    }
}

export const listFiles = async () => {
    try {
        const token = getCookie('token')
        console.log(token)
        if (token === "") {
            return {files: [], error: "Authorization error"}
        }

        const response = await axios.get(`${ServerBaseURL}/file`, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });

        console.log(response)
        return {files: response.data.data.data, error: ""}
    } catch (err) {
        if (err.response !== undefined) {
            return {error: err.response.data.message}
        }
        return {files: [], error: "Something unexpected happened, try again later!"}
    }
}

export const deleteFile = async (id) => {
    try {
        const token = getCookie('token')
        console.log(token)
        if (token === "") {
            return {error: "Authorization error"}
        }

        const response = await axios.delete(`${ServerBaseURL}/file?id=${id}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });

        console.log(response)
    } catch (err) {
        if (err.response !== undefined) {
            return {error: err.response.data.message}
        }
        return {error: "Something unexpected happened, try again later!"}
    }
}

export const deleteFileFromVectorStore = async (vsId, fId) => {
    try {
        const token = getCookie('token')
        console.log(token)
        if (token === "") {
            return {error: "Authorization error"}
        }

        const response = await axios.delete(`${ServerBaseURL}/file/vector-store?vsId=${vsId}&fId=${fId}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });

        console.log(response)
    } catch (err) {
        if (err.response !== undefined) {
            return {error: err.response.data.message}
        }
        return {error: "Something unexpected happened, try again later!"}
    }
}

export const createVectorStore = async (name) => {
    try {
        const token = getCookie('token')
        console.log(token)
        if (token === "") {
            return {error: "Authorization error"}
        }

        const response = await axios.post(`${ServerBaseURL}/file/vector-store?`, {name: name},
            {
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });

        console.log(response)
        return response.data.id
    } catch (err) {
        if (err.response !== undefined) {
            return {error: err.response.data.message}
        }
        return {error: "Something unexpected happened, try again later!"}
    }
}

export const addFilesToVectorStore = async (fileIds, vectorStoreId) => {
    try {
        const token = getCookie('token')
        console.log(token)
        if (token === "") {
            return {error: "Authorization error"}
        }

        const response = await axios.post(`${ServerBaseURL}/file/vector-store-file?`, {fileIds: fileIds, vectorStoreId: vectorStoreId},
            {
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });

        console.log(response)
        return response.data.id
    } catch (err) {
        if (err.response !== undefined) {
            return {error: err.response.data.message}
        }
        return {error: "Something unexpected happened, try again later!"}
    }
}

export const updateUser = async(apiKey) => {
    try {
        const token = getCookie('token')
        console.log(token)
        if (token === "") {
            return {error: "Authorization error"}
        }

        await axios.post(`${ServerBaseURL}/authentication/update`, {apiKey: apiKey},
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                },
            });
    } catch (err) {
        if (err.response !== undefined) {
            return {error: err.response.data.message}
        }
        return {error: "Something unexpected happened, try again later!"}
    }
}

export const sendMail = async(subject) => {
    try {
        const token = getCookie('token')
        console.log(token)
        if (token === "") {
            return {error: "Authorization error"}
        }

        await axios.post(`${ServerBaseURL}/mail`, {subject: subject},
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                },
            });
    } catch (err) {
        if (err.response !== undefined) {
            return {error: err.response.data.message}
        }
        return {error: "Something unexpected happened, try again later!"}
    }
}

export const getAssistantFiles = async (vectorStoreId) => {
    try {
        const token = getCookie('token')
        console.log(token)
        if (token === "") {
            return {files: [], error: "Authorization error"}
        }

        const response = await axios.get(`${ServerBaseURL}/file/assistant?vectorStoreId=${vectorStoreId}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });

        console.log(response)
        return {files: response?.data || [], error: ""}
    } catch (err) {
        if (err.response !== undefined) {
            return {error: err.response.data.message}
        }
        return {files: [], error: "Something unexpected happened, try again later!"}
    }
}

export const getDashboardSummary = async () => {
    try {
        const token = getCookie('token')
        console.log(token)
        if (token === "") {
            return {error: "Authorization error"}
        }

        const response = await axios.get(`${ServerBaseURL}/dashboard/summary`, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });

        console.log(response)
        return {summary: response.data.data, error: ""}
    } catch (err) {
        if (err.response !== undefined) {
            return {error: err.response.data.message}
        }
        return {error: "Something unexpected happened, try again later!"}
    }
}

export const getPricingPlans = async () => {
    try {
        const token = getCookie('token')
        console.log(token)
        if (token === "") {
            return {error: "Authorization error"}
        }

        const response = await axios.get(`${ServerBaseURL}/pricing/plans`, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });

        console.log(response)
        return {items: response.data.items, error: ""}
    } catch (err) {
        if (err.response !== undefined) {
            return {error: err.response.data.message}
        }
        return {error: "Something unexpected happened, try again later!"}
    }
}
export const createSubscription = async (subscriptionPlanId) => {
    try {
        const token = getCookie('token')
        console.log(token)
        if (token === "") {
            return {error: "Authorization error"}
        }

        const response = await axios.post(`${ServerBaseURL}/pricing/subscription`, {
            subscriptionPlanId
        }, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });

        console.log(response)
    } catch (err) {
        if (err.response !== undefined) {
            return {error: err.response.data.message}
        }
        return {error: "Something unexpected happened, try again later!"}
    }
}
export const getSubscription = async () => {
    try {
        const token = getCookie('token')
        console.log(token)
        if (token === "") {
            return {error: "Authorization error"}
        }

        const response = await axios.get(`${ServerBaseURL}/pricing/subscription`, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });

        console.log(response)
        return {items: response.data, error: ""}
    } catch (err) {
        if (err.response !== undefined) {
            return {error: err.response.data.message}
        }
        return {error: "Something unexpected happened, try again later!"}
    }
}

export const getConversationMessagesWeekly = async () => {
    try {
        const token = getCookie('token')
        console.log(token)
        if (token === "") {
            return {error: "Authorization error"}
        }

        const response = await axios.get(`${ServerBaseURL}/dashboard/messages/weekly`, {
            headers: {
                "Authorization": `Bearer ${token}`
            },
        });

        console.log(response)
        return {messageCounts: response.data.data[0], error: ""}
    } catch (err) {
        if (err.response !== undefined) {
            return {error: err.response.data.message}
        }
        return {error: "Something unexpected happened, try again later!"}
    }
}
export const exportAssistantMessages = async (assistantId, startDate, endDate) => {
    try {
        const token = getCookie('token');
        if (!token) {
            return { error: 'Authorization error' };
        }

        const response = await axios.post(`${ServerBaseURL}/conversation/messages`,
            {
                assistantId,
                startDate,
                endDate,
            }, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            responseType: 'blob',
        });

        const blob = new Blob([response.data], { type: 'application/jsonl' });
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `conversation_history_${assistantId}.jsonl`;
        link.click();
        window.URL.revokeObjectURL(url);

        return { message: 'File downloaded successfully', error: '' };
    } catch (err) {
        return {error: err}
    }
};