import React, {useEffect, useState} from "react";
import '../styles/SelectFileModal.css';
import {addFilesToVectorStore, listFiles} from "../clients/farmland";

const SelectFileModal = ({ vectorStoreId, onClose }) => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [files, setFiles] = useState([]);
    const [error, setError] = useState("");

    useEffect(() => {
        const fetchFiles = async () => {
            const result = await listFiles();
            if (result.error) {
                setError(result.error);
            } else {
                setFiles(result.files);
            }
        };
        fetchFiles().then();
    }, []);


    const handleFileSelect = (file) => {
        setSelectedFiles((prevSelectedFiles) => {
            if (prevSelectedFiles.includes(file)) {
                return prevSelectedFiles.filter((f) => f !== file);
            } else {
                return [...prevSelectedFiles, file];
            }
        });
    };

    const handleAddFiles = async (fileIds, vectorStoreId) => {
        try {
            await addFilesToVectorStore(fileIds, vectorStoreId)
            onClose();
        } catch (error) {
            console.error('Error sending files:', error);
        }
    };

    return (
        <div className="select-file-modal">
            <div className="select-file-modal-content">
                <h2>Select from Existing Files</h2>
                <div className="select-file-grid">
                    {files.map((file, index) => (
                        <div key={index} className="select-file-item">
                            <p>{file.filename}</p>
                            <input
                                type="checkbox"
                                checked={selectedFiles.includes(file)}
                                onChange={() => handleFileSelect(file)}
                            />
                        </div>
                    ))}
                </div>
                <div className="select-file-actions">
                    <button className="select-file-add-button"
                            onClick={() => handleAddFiles(selectedFiles.map(i => i.id), vectorStoreId)}>Add Selected
                    </button>
                    <button className="select-file-close-button" onClick={onClose}>Close</button>
                </div>
            </div>
        </div>

    );
};

export default SelectFileModal;
