import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';

function Google() {
    const handleSuccess = (response) => {
        console.log('Google Sign-In successful:', response);
    };

    const handleError = () => {
        console.error('Google Sign-In failed');
    };

    return (
        <GoogleOAuthProvider clientId="">
            <div className="google-oauth-modal">
                <h1>Sign in with Google</h1>
                <GoogleLogin onSuccess={handleSuccess} onError={handleError} />
            </div>
        </GoogleOAuthProvider>
    );
}

export default Google;
