import React, {useEffect, useState} from "react";
import {deleteFileFromVectorStore, getAssistantFiles} from "../clients/farmland";
import ErrorModal from "./ErrorModal";
import Spinner from "./Spinner";
import '../styles/AssistantFileListModal.css'
import ConfirmModal from "./ConfirmModal";
import {paginate} from "../helper/pagination";

const AssistantFileListModal = ({vectorStoreId}) => {
    const [assistantFiles, setAssistantFiles] = useState([]);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = React.useState(false);
    const [fileId, setFileId] = React.useState('');
    const [currentPage, setCurrentPage] = useState(1);

    const pageSize = 5;


    useEffect(() => {
        if (!isDataLoaded){
            const fetchFiles = async () => {
                try {
                    const storedFiles = sessionStorage.getItem(`assistantFiles_${vectorStoreId}`);
                    if (storedFiles) {
                        const parsedFiles = JSON.parse(storedFiles);
                        setAssistantFiles(parsedFiles);
                    } else {
                        console.log(vectorStoreId)
                        const fetchedFiles = await getAssistantFiles(vectorStoreId);
                        console.log(fetchedFiles)

                        setAssistantFiles(fetchedFiles.files);
                    }
                    setIsDataLoaded(true);
                    setIsLoading(false);
                } catch (e) {
                    setError("Error occurred while acquiring files");
                }
            };

            fetchFiles().then();
        }
    }, [isDataLoaded]);

    const handleErrorOk = () => {
        setError('');
    };


    const handleDeleteFile = async (vsId, fileId) => {
        setIsLoading(true)
        await deleteFileFromVectorStore(vsId, fileId)
        setIsLoading(false)
        setIsConfirmModalOpen(false)
        sessionStorage.removeItem(`assistantFiles_${vectorStoreId}`);
    }

    const handleOpenConfirmModal = (fileId) => {
        setIsConfirmModalOpen(true);
        setFileId(fileId)
    };

    const handleCancel = () => {
        setIsConfirmModalOpen(false);
    };

    const bytesToMB = (bytes) => (bytes / (1024 * 1024)).toFixed(2) + ' MB';


    const { paginatedItems: paginatedFiles, totalPages } = paginate(assistantFiles, currentPage, pageSize);

    const handleNextPage = () => {
        if (currentPage < totalPages) setCurrentPage((prev) => prev + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) setCurrentPage((prev) => prev - 1);
    };


    return (
        <div className="assistant-file-list-container">
            <div className="assistant-file-list-sub">
                <h1>Assistant Files</h1>
                {isLoading ? (
                    <Spinner />
                ) : assistantFiles?.length === 0 ? (
                    <p>There are no files uploaded for this assistant.</p>
                ) : (
                    <>
                        <div className="file-list">
                            {paginatedFiles.map((file) => (
                                <div key={file.fileId} className="file-card">
                                    <div className="file-info">
                                        <strong>Name:</strong> {file.filename} <br />
                                        <strong>Size:</strong> {bytesToMB(file.bytes)} <br />
                                    </div>
                                    <button
                                        className="delete-button"
                                        onClick={() => handleOpenConfirmModal(file.fileId)}
                                    >
                                        &times;
                                    </button>
                                </div>
                            ))}
                        </div>

                        <div className="pagination">
                            <button
                                onClick={handlePreviousPage}
                                disabled={currentPage === 1}
                            >
                                Previous
                            </button>
                            <span>
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                onClick={handleNextPage}
                                disabled={currentPage === totalPages}
                            >
                                Next
                            </button>
                        </div>
                    </>
                )}
            </div>

            {isConfirmModalOpen && (
                <div className="confirm-modal">
                    <ConfirmModal
                        handleCancel={handleCancel}
                        handleSave={() => handleDeleteFile(vectorStoreId, fileId)}
                        savePH={'Delete'}
                        confirmationText={"Do you want to delete this file from the assistant?"}
                    />
                </div>
            )}

            {error !== "" && (
                <div className="assistant-error-modal">
                    <ErrorModal handleErrorOk={handleErrorOk} errorMessage={error} />
                </div>
            )}
        </div>
    );
}

export default AssistantFileListModal;